import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import { mapState } from 'vuex'
import { GtrParticipantField, CustomFieldOption } from '@/interfaces/common.interface'

@Component<GtrCustomFieldModal>({
  name: 'GtrCustomFieldModal',
  computed: {
    ...mapState('event', ['participant_fields']),
    ...mapState('option', ['option_groups'])
  }
})
export default class GtrCustomFieldModal extends Vue {
  /**
   * The width for the modal.
   */
  @Prop({ required: false, type: Number, default: 450 })
  width: number | undefined;

  /**
   * The value to show/hide the modal.
   */
  @Prop({ required: true, type: Boolean, default: false })
  value: boolean | undefined;

  /**
   * Array of additional fields to add to the dropdown.
   */
  @Prop({ required: false, type: Array, default: () => [] })
  additionalFields: CustomFieldOption[] | undefined

  /**
   * Ref for the form element.
   */
  @Ref()
  readonly form!: InstanceType<typeof HTMLFormElement>

  /**
   * Ref for the static text input.
   */
  @Ref()
  readonly staticTextContentRef!: InstanceType<typeof HTMLInputElement>

  /**
   * The list of custom field options.
   */
  participant_fields!: Array<GtrParticipantField>;

  /**
   * The list of option groups.
   */
  // TODO(z): create a type for this.
  option_groups!: Array<unknown>;

  /**
   * @memberof GtrCustomFieldModal
   * @name event_uuid
   * @type {string}
   * @description The event uuid.
   */
  event_uuid: string = this.$route.params.event_uuid;

  private systemGeneratedFields: Array<string> = [
    'first_name',
    'last_name',
    'company',
    'title',
    'barcodes',
    'phone_number',
    'reg_status'
  ];

  private fieldsToExclude: Array<string> = [
    'a2z_company_association',
    'a2z_company_number',
    'a2z_email',
    'a2z_id',
    'activation_codes',
    'alt_credits',
    'badge_print_date',
    'badge_print_status',
    'can_export_certificates',
    'certificate_id',
    'comped',
    'comped_secret',
    'credits',
    'cur_reg_fee',
    'cur_reg_fee_group',
    'cur_reg_fee_lr',
    'cur_reg_fee_mc_trade',
    'date_canceled',
    'date_registered',
    'date_transferred',
    'deleted_by',
    'deletion_reason',
    'devices_micro_scanner',
    'devices_mobile_app',
    'devices_pro_scanner',
    'exported_certificate',
    'external_id',
    'extra_bcc',
    'has_started_filling_surveys',
    'has_surveys_to_fill',
    'highest_submitted_page',
    'highest_submitted_page_lr',
    'import_id',
    'import_uuid',
    'invoice_number',
    'language',
    'login_key',
    'lr_orders',
    'lr_payment_status',
    'lr_phone',
    'lr_status',
    'mcpro_profile_id',
    'mctrade_profile_id',
    'notes',
    'onsite_device',
    'onsite_device_status',
    'onsite_notes',
    'onsite_phone',
    'participant_type',
    'password',
    'password_reset_token',
    'pay_for_another',
    'payment_details',
    'payment_status',
    'payment_status_group',
    'payment_total',
    'payment_total_lr',
    'payment_vault_id',
    'profile_photo',
    'referral_code',
    'stripe_customer_id',
    'transaction_card_type',
    'transaction_cc_last4',
    'transaction_id',
    'transferred_from',
    'updated_at',
    'uuid'
  ]

  /**
   * @memberof GtrCustomFieldModal
   * @getter customFieldOptions
   * @description Boolean to show/hide the modal.
   * @returns {boolean}
   */
  get show (): boolean {
    return this.value as boolean
  }

  /**
   * @memberof GtrCustomFieldModal
   * @getter showStaticTextInput
   * @description getter to determine if the static text content input should be shown.
   */
  get showStaticTextInput (): boolean {
    return (this.selectedField && this.selectedField.value === 'static_text') as boolean
  }

  /**
   * @memberof GtrCustomFieldModal
   * @getter customFieldOptions
   * @description getter to return the sorted custom field options.
   * @returns {Array<CustomFieldOption>}
   */
  get customFieldOptions (): Array<CustomFieldOption> {
    const staticTextOption: Array<CustomFieldOption> = [
      {
        label: 'Static Text',
        value: 'static_text'
      },
      {
        label: 'City State',
        value: 'city_state'
      },
      {
        label: 'City State Country',
        value: 'city_state_country'
      },
      {
        label: 'Full Name',
        value: 'full_name'
      },
      {
        label: 'Name and Credentials',
        value: 'name_and_credentials'
      }
    ]

    // Filter out system generated fields and fields with option groups.
    const fields = this.participant_fields
      .filter(
        ({ field }) => (!this.systemGeneratedFields.includes(field) && !this.fieldsToExclude.includes(field)))
      .filter(({ option_group_uuid }) => (!option_group_uuid))
      .map(
        ({ label, field: value, option_group_uuid: option_group }) => ({
          label: option_group ? `Option Group: ${label}` : label,
          value,
          option_group
        })
      )

    // transform option groups into custom field options.
    const filteredOptionGroups = (this.option_groups as any).filter(
      option => !option.name.includes('Lead Retrieval') && !option.name.includes('Payment Methods') && !option.name.includes('Additional User Line Items')
    )
    const optionGroups = filteredOptionGroups.map(
      ({ name: label, uuid: value }) => ({ label: `Option Group: ${label}`, value })
    )

    // merge it all together and sort it.
    return staticTextOption
      .concat(fields as unknown as Array<CustomFieldOption>)
      .concat(optionGroups as unknown as Array<CustomFieldOption>)
      .concat(this.additionalFields as Array<CustomFieldOption>)
      .sort((a, b) => a.label.localeCompare(b.label))
  }

  /**
   * @memberof GtrCustomFieldModal
   * @method created
   * @description Created lifecycle hook. Gets the participant fields for the event.
   */
  async created () {
    try {
      await this.$store.dispatch('event/getParticipantFields', this.event_uuid)
    } catch (e) {
      Container.get(Notification).error('Error getting participant fields')
    }
  }

  /**
   * the content to use for the static text field.
   */
  staticTextContent?: string = 'Enter Text';

  /**
   * @memberof GtrCustomFieldModal
   * @name selectedField
   * @type {CustomFieldOption | null}
   * @default null
   * @description The selected field.
   */
  selectedField: CustomFieldOption | null = null;

  /**
   * @memberof GtrCustomFieldModal
   * @method onFieldChange
   * @description On change handler that will focus the static text input if the static text field is selected.
   * @returns {void}
   */
  onFieldChange (item): void {
    if (this.selectedField && this.selectedField.value === 'static_text') {
      this.$nextTick(() => {
        this.staticTextContentRef.focus()
      })
    }
  }

  /**
   * @memberof GtrCustomFieldModal
   * @method onAddCustomField
   * @description Emits the add event, which will pass the selected field and the static text content up to the svg builder.
   * @returns {void}
   */
  onAddCustomField (): void {
    if (!this.selectedField) return
    // Check if it's static text
    if (this.selectedField.value === 'static_text') {
      const acceptableFields: any[] = []
      // Check string for field(s)
      const regex = /\{\{(.*?)\}\}/g
      const matches = this.staticTextContent?.match(regex)
      // Remove curly braces from matches
      const results = matches?.map(match => match.slice(2, -2))
      // Combine all fields
      const customFieldOptionsValues = this.customFieldOptions.map(field => field.value)
      const allFieldOptions = [...this.$data.systemGeneratedFields, ...customFieldOptionsValues]
      // Check if fields match any of the results
      // Push matching result to acceptableFields array
      if (results) {
        for (const result of results) {
          for (const field of allFieldOptions) {
            if (field === result) {
              if (!acceptableFields.includes(result)) {
                acceptableFields.push(result)
              }
            }
          }
        }
      }
      // Use acceptableFields to filter results
      // The remaining are unacceptable fields
      const unacceptableFields = results?.filter(field => !acceptableFields.includes(field))
      // Return out of function if unacceptable fields
      if (unacceptableFields?.length) {
        for (const field of unacceptableFields) {
          Container.get(Notification).error(`Merge field {{${field}}} not found.`)
        }
        return
      }
    }
    const custom_field: CustomFieldOption = {
      ...this.selectedField,
      text_content: this.selectedField.value === 'static_text' ? this.staticTextContent : null
    }
    this.$emit('add-custom-field', custom_field)
    this.resetForm()
  }

  /**
   * @memberof GtrCustomFieldModal
   * @method onCloseOrCancel
   * @description Emits the close event, to close the modal.
   * @returns {void}
   */
  onCloseOrCancel (): void {
    this.$emit('close')
    this.resetForm()
  }

  /**
   * @memberof GtrCustomFieldModal
   * @method resetForm
   * @description Resets the form.
   * @returns {void}
   */
  resetForm (): void {
    this.selectedField = null
    this.staticTextContent = 'Enter Text'
  }
}
